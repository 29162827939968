.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.autocomplete-container {
  position: relative;
}

.suggestions-list {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #eee;
}

input {
  padding: 10px;
  width: 340px;
}

.delete-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: darkred;
}
